import React from 'react'
import Button from '@mui/material/Button'
import { useDispatch } from 'react-redux'
import { addProduct } from '../../store/cartSlice'
import ItemAdded from './itemAdd'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'

const AddToCartButton = ({ workshop, displayAdd, setDisplayAdd }) => {
  const dispatch = useDispatch()

  const handleAdd = () => {
    ReactGA.event({
      category: 'button',
      action: 'Cart Add'
    })

    ReactPixel.track('AddToCart', {
      content_category: 'Add_To_Cart'
    })

    setDisplayAdd(true)
    dispatch(addProduct(workshop))
    // }
  }

  const handleClose = () => {
    setDisplayAdd(!displayAdd)
  }

  return (
    <>
      <Button
        id='add-to-cart'
        disabled={!workshop.priceInfo}
        variant='contained'
        onClick={() => {
          handleAdd(workshop)
        }}
      >
        {workshop.priceInfo ? 'Enroll' : 'Loading...'}
      </Button>
      {displayAdd ? (
        <ItemAdded handleClose={handleClose} course={workshop} />
      ) : (
        <></>
      )}
    </>
  )
}

export default AddToCartButton
