import React, { useState, useEffect } from 'react'
import WorkshopInfo from './workshopInfo'
import { Card, Button, Typography, useMediaQuery, Box } from '@mui/material'
import AddToCartButton from './addToCartButton'
import { useSelector } from 'react-redux'
import WorkshopModal from './workshopModal'

const WorkshopCard = ({ workshop, handleProductForm, toggleCustomer }) => {
  const permissions = useSelector((state) => state.login.user?.permissions?.[0])
  const [showModal, setShowModal] = useState(false)
  const [inThePast, setInThePast] = useState(false)
  const [checkCapacity, setCheckCapacity] = useState('Enroll')
  const [displayAdd, setDisplayAdd] = useState(false)

  useEffect(() => {
    let today = new Date()
    let startDate = new Date(workshop.metadata.startDate)
    //allow 2 days after start to sign up
    if (!permissions || permissions < 4) {
      startDate.setDate(startDate.getDate() + 2)
      let tmp = today - startDate > 0
      setInThePast(tmp)
    }

    if (workshop.info?.students?.length === workshop.info?.cap) {
      setCheckCapacity('Full')
    } else if (
      workshop.info?.students.length >=
      parseInt(workshop.info?.cap) - 3
    ) {
      setCheckCapacity('Almost Full')
    } else {
      setCheckCapacity('Enroll')
    }
  }, [])

  const styles = {
    tempWeb: {
      maxHeight: 200,
      overflow: 'auto'
    },
    title: {
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: '15px auto 10% 10% 10%',
      width: '100%'
    }
  }

  let check = useMediaQuery('(min-width:600px)')
  if (check) {
    styles.tempWeb = {
      maxWidth: '400px',
      margin: '0 auto',
      textAlign: 'center',
      height: '80vh',
      paddingTop: '10vh'
    }
  }

  const handleModal = () => {
    setShowModal(false)
  }
  return (
    <>
      <Card
        onClick={(e) => {
          if (!displayAdd && e.target.tagName !== 'BUTTON') {
            setShowModal(true)
          }
        }}
        sx={{
          height: '100%',
          padding: '10px 10px',
          display: 'flex',
          flexDirection: 'column',
          '&:hover': {
            //add hover property if on overview
            cursor: 'pointer',
            boxShadow:
              '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 5px 10px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
          }
        }}
      >
        <WorkshopInfo
          workshop={workshop}
          showAdminDetails={permissions >= 3 && !toggleCustomer}
          showParentDetails={false}
        />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {permissions >= 3 && !toggleCustomer ? (
            <Box
              sx={{
                padding: '4px 10px',
                minWidth: '64px',
                borderRadius: '20px',
                backgroundColor: inThePast ? 'lightGray' : 'primary.main',
                color: inThePast ? 'inherit' : '#fff'
              }}
            >
              {inThePast
                ? 'Closed'
                : `${workshop.info?.students?.length} / ${workshop.info?.cap}`}
            </Box>
          ) : inThePast || checkCapacity == 'Full' ? (
            <Button
              id='closed-button'
              variant='contained'
              style={{ backgroundColor: 'grey' }}
            >
              CLOSED
            </Button>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {checkCapacity == 'Almost Full' ? (
                <Typography>Almost Full</Typography>
              ) : null}
              <AddToCartButton
                workshop={workshop}
                displayAdd={displayAdd}
                setDisplayAdd={setDisplayAdd}
              />
            </div>
          )}
        </Box>
      </Card>
      <WorkshopModal
        handleModal={handleModal}
        workshop={workshop}
        handleProductForm={handleProductForm}
        showModal={showModal}
        inThePast={inThePast}
        checkCapacity={checkCapacity}
        toggleCustomer={toggleCustomer}
        studentList={workshop?.info?.students}
        workshopID={workshop.id}
      />
    </>
  )
}

export default WorkshopCard
