import React, { useState, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  IconButton,
  Typography,
  useMediaQuery
} from '@mui/material'
import { Menu } from '@mui/icons-material'
import Footer from '../component/footer'
import { Helmet } from 'react-helmet'
import { GetLocations } from '../api/locations'

const LandingContainer = () => {
  const navigate = useNavigate()
  const [openNavDrawer, setOpenNavDrawer] = useState(false)
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const [locations, setLocations] = useState([])

  useEffect(() => {
    let mounted = true
    async function handleAsync() {
      let getLocations = await GetLocations()
      setLocations(getLocations.data)
    }
    if (mounted && locations.length == 0) {
      handleAsync()
    }
    return () => (mounted = false)
  }, [])

  let styles = {
    box: {
      width: { xs: '90%', md: '70%' },
      maxWidth: '1200px',
      margin: 'auto auto'
    },
    yellow: '#F3C34B',
    lightGrey: '#F2F4F7',
    red: '#EE4239',
    green: '#6EA44F',
    lightBlue: '#ADC5E7',
    blue: '#56A1D6',
    purple: '#8F7EB5',
    darkGrey: '#3e3e3e'
  }

  const NAV_BUTTONS = [
    // { text: 'Holiday Camp', link:'/home'},
    { text: 'Spring Camp', link: '/spring-camps' },
    { text: 'Summer Camp', link: '/home/summer-camps' },
    { text: 'Membership', link: '/home/memberships' },
    // { text: 'Other Activities', link: '/upcoming-camps' },
    { text: 'Enroll', link: '/enroll' },
    {
      text: 'Blog',
      link: 'https://www.rr-stempartners.org/blog',
      externalLink: true
    },
    { text: 'Sign In', link: '/login' }
  ]

  const buttonColor = (button) => {
    if (button.link == window.location.pathname) {
      return styles.red
    }
  }
  const handleNavigation = (button) => {
    if (button.externalLink) {
      window.open(button.link)
    } else {
      navigate(button.link)
    }
  }
  return (
    <>
      <Helmet>
        <title>STEM Summer Camps in Southern California | Rolling Robots</title>
        <meta
          name='description'
          content='Discover the perfect summer camp at Rolling Robots! We offer hands-on fun and learning that kids aged 5-15 will love. Join us at our 20+ locations in Southern California!'
        />
      </Helmet>
      <Drawer
        anchor='top'
        open={openNavDrawer}
        onClose={() => setOpenNavDrawer(false)}
      >
        <List>
          {NAV_BUTTONS.map((button) => {
            return (
              <ListItem key={button.text} disablePadding>
                <ListItemButton onClick={() => handleNavigation(button)}>
                  <ListItemText>{button.text}</ListItemText>
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </Drawer>
      <Box
        id='navigation-bar'
        sx={{ width: '100vw', height: '10vh', backgroundColor: '#fff' }}
      >
        <Box
          sx={{
            ...styles.box,
            height: '100%',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start' }}
          >
            <Box
              component='img'
              src={require('../images/rolling_robots_logo_small_transparent.png')}
              alt='Rolling Robots Logo'
              onClick={() => navigate('/')}
              sx={{
                maxWidth: { xs: '200px', sm: '300px' },
                '&:hover': { cursor: 'pointer' }
              }}
            />
          </Box>
          {mobile ? (
            <>
              <IconButton onClick={() => setOpenNavDrawer(!openNavDrawer)}>
                <Menu />
              </IconButton>
            </>
          ) : (
            NAV_BUTTONS.map((button) => {
              return (
                <Typography
                  key={button.text}
                  sx={{
                    padding: '0px 15px',
                    '&:hover': { cursor: 'pointer' },
                    fontSize: '0.8rem',
                    fontWeight: 500,
                    textAlign: 'center',
                    color: buttonColor(button)
                  }}
                  onClick={() => handleNavigation(button)}
                >
                  {button.text}
                </Typography>
              )
            })
          )}
        </Box>
      </Box>
      <Outlet context={[locations]} />
      <Footer styles={styles} />
    </>
  )
}

export default LandingContainer
