import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Collapse,
  Box,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Tooltip,
  Chip,
  Skeleton
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { deleteProduct } from '../../../api/products'
import { removeProduct } from '../../../store/adminSlice'
import dateHandler from '../../../utils/dateHandler'
import timeHandler from '../../../utils/timeHandler'
import ProductsFilter from './productsFilter'
import LoadingComp from '../../util/loading'
import ProductDate from './productDate'
import { useNavigate } from 'react-router-dom'

function Row({ row, setForm }) {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [productToDelete, setProductToDelete] = useState(null)
  const course = useSelector((state) => state.parent.courses).filter(
    (tmpCourse) => tmpCourse._id === row.metadata.course
  )[0]
  const dispatch = useDispatch()

  const styles = {
    buttonStyle: {
      margin: '0 5px',
      color: '#FFFFFF',
      backgroundColor: '#C80F2D'
    },
    space: {
      margin: '15px auto'
    },
    toggleButton: {
      color: 'white',
      backgroundColor: '#3596CE'
    }
  }

  const handleSelectDelete = (product) => {
    setProductToDelete(product)
    setOpenDelete(true)
  }

  const handleCloseDelete = () => {
    setOpenDelete(false)
  }

  const handleConfirmDelete = async () => {
    let resp = await deleteProduct({ product: productToDelete })
    if (resp.success) {
      dispatch(removeProduct(productToDelete))
      handleCloseDelete()
      setProductToDelete(null)
    } else {
      alert(resp)
    }
  }

  let startTime = row.metadata.startTime
  let endTime = row.metadata.endTime
  let startTimeMinute = row.metadata.startTimeMinute
  let endTimeMinute = row.metadata.endTimeMinute

  const DateCells = () => {
    if (dateHandler.isConsecutiveDates(row.info.selectDates)) {
      return (
        <>
          <TableCell>
            Start Date: {dateHandler.formatWithWeekday(row.metadata.startDate)}
          </TableCell>
          <TableCell>
            End Date: {dateHandler.formatWithWeekday(row.metadata.endDate)}
          </TableCell>
        </>
      )
    } else {
      return (
        <>
          <TableCell>
            Dates:{' '}
            {row.info.selectDates.map((date, i) => (
              <>
                {i > 0 ? ', ' : null}
                {dateHandler.formatWithWeekday(date)}
              </>
            ))}
          </TableCell>
        </>
      )
    }
  }

  return (
    <React.Fragment key={row.id}>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell align='left'>
          <img
            src={require(`../../../images/courseIcons/${
              row.info?.icon || course?.icon || 'robie.png'
            }`)}
            loading='lazy'
            width='50px'
            height='50px'
          />
        </TableCell>
        <TableCell align='center'>{row.metadata.courseName}</TableCell>
        <TableCell
          align='center'
          style={{ paddingLeft: '0px', paddingRight: '0px' }}
        >
          {row.priceInfo ? (
            `$${row.priceInfo?.unit_amount / 100}`
          ) : (
            <Skeleton
              variant='text'
              sx={{ fontSize: '1.5rem', width: '80px' }}
            />
          )}
        </TableCell>
        <TableCell style={{ paddingLeft: '0px', paddingRight: '1px' }}>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='h6' gutterBottom component='div'>
                Product Info:
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <DateCells />
                    <TableCell>
                      Time:{' '}
                      {timeHandler.getTimeString({
                        startTime,
                        endTime,
                        startTimeMinute,
                        endTimeMinute
                      })}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Location: {row.metadata.locationName}</TableCell>
                    <TableCell>Course: {row.metadata.courseName}</TableCell>
                    <TableCell>Enrolled: {row.info.students?.length}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Instructors:{' '}
                      {row.instructors.length > 0
                        ? row.instructors.map((instructor) => (
                            <Chip
                              key={instructor._id}
                              label={`${instructor.info.name.fName} ${instructor.info.name.lName} `}
                              onClick={() =>
                                navigate(`/staff-view/${instructor._id}`)
                              }
                            />
                          ))
                        : 'None'}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
              <Typography variant='h6' gutterBottom component='div'>
                Stripe Info:
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell>Product ID: {row.stripeID}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{display: 'flex', flexDirection: 'row'}}>
                      Price ID:
                      {row.priceInfo ? (
                        row.priceInfo?.id
                      ) : (
                        <Skeleton
                          variant='text'
                          sx={{ fontSize: '1.5rem', width: '80px' }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Workshop: {row.metadata.seasonCode}</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'left',
                  margin: '5px 0'
                }}
              >
                <Typography variant='h6' gutterBottom component='div'>
                  Options:
                </Typography>
                <Button
                  style={styles.buttonStyle}
                  onClick={() => {
                    setForm(row, 'edit')
                  }}
                >
                  Edit
                </Button>
                <Button
                  style={styles.buttonStyle}
                  onClick={() => {
                    setForm(row, 'duplicate')
                  }}
                >
                  Duplicate
                </Button>
                {row.info.students?.length > 0 ? (
                  <Tooltip title='Products with enrolled students cannot be deleted'>
                    <span>
                      <Button disabled>Delete</Button>
                    </span>
                  </Tooltip>
                ) : (
                  <Button
                    style={styles.buttonStyle}
                    onClick={() => {
                      handleSelectDelete(row)
                    }}
                  >
                    Delete
                  </Button>
                )}
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog open={openDelete} onClose={handleCloseDelete}>
        <DialogTitle>Delete {productToDelete?.name}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

const columns = [
  { id: 'icon', label: '' },
  { id: 'name', label: 'Name' },
  { id: 'price', label: 'Price' },
  { id: 'toggle', label: '' }
]

const ProductsTable = ({
  workshops,
  pageWorkshops,
  setForm,
  loading,
  pagination,
  setPagination,
  workshopsCount
}) => {
  // const [rowsPerPage, setRowsPerPage] = useState(25)
  const rowsPerPage = 25

  const filteredProducts = useSelector(
    (state) => state.workshop.filteredWorkshops
  )

  //TO DO: move this logic to container?
  const handleChangePage = (event, newPage) => {
    setPagination(newPage)
  }

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value)
  //   setPage(0)
  // }

  return (
    <Box sx={{ width: '80%', margin: '0px auto 100px auto' }}>
      {!loading ? (
        <>
          {/* TO DO: Update ProductDate and ProductsFilter to work with new data structure*/}
          {/* <ProductDate /> */}
          {/* <ProductsFilter /> */}
          {filteredProducts.length === 0 ? (
            <Typography>No workshops match the criteria</Typography>
          ) : (
            <Paper sx={{ overflow: 'hidden' }}>
              <TableContainer>
                <Table stickyHeader aria-label='sticky table'>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id}>{column.label}</TableCell>
                      ))}
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pageWorkshops.map((row) => {
                      return <Row key={row.id} row={row} setForm={setForm} />
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                // rowsPerPageOptions={[10, 25, 100]}
                rowsPerPageOptions={[10]}
                component='div'
                count={workshopsCount}
                rowsPerPage={rowsPerPage}
                page={pagination}
                onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </>
      ) : (
        <LoadingComp />
      )}
    </Box>
  )
}

export default ProductsTable
